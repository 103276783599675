import { ThemeUIStyleObject } from 'theme-ui';

const BASE_LIST_STYLES: ThemeUIStyleObject = {
  fontSize: '16px',
  color: '#434350',
  fontWeight: 500,
  lineHeight: '27px',
};
export const MULTIPLE_ITEMS_LIST_STYLES: ThemeUIStyleObject = {
  ...BASE_LIST_STYLES,
  margin: 0,
  paddingLeft: '8px',
};
export const SINGLE_ITEM_LIST_STYLES: ThemeUIStyleObject = {
  ...BASE_LIST_STYLES,
  margin: 0,
  padding: 0,
  listStyle: 'none',
  fontSize: '16px',
  color: '#434350',
  fontWeight: 500,
  lineHeight: '27px',
};
export const CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  gap: '16px',
  padding: '16px',
  borderRadius: '12px',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
  backgroundColor: 'white',
  position: 'relative',
};
export const TITLE_TEXT_STYLES: ThemeUIStyleObject = {
  fontWeight: 700,
  fontSize: ['18px', '20px'],
  textTransform: 'capitalize',
  lineHeight: '24px',
};

export const CONTENT_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '235px',
};

/** @jsx jsx */
// libs
import React, { useCallback, useMemo, useState } from 'react';
import { Box, jsx, Text } from 'theme-ui';

// components
import { Icon } from '@sprinklr/shared-lib/components/icon';

// types
import { UseCase } from '../../types';
import { Button, GenericModal } from '@sprinklr/shared-lib';
import { useResponsiveValue } from '@theme-ui/match-media';

// styles
import {
  SINGLE_ITEM_LIST_STYLES,
  MULTIPLE_ITEMS_LIST_STYLES,
  CONTAINER_STYLES,
  TITLE_TEXT_STYLES,
  CONTENT_CONTAINER_STYLES,
} from './styles';

type UseCaseCardProps = {
  useCase: UseCase;
};

export const UseCaseCard = ({ useCase }: UseCaseCardProps) => {
  const { title, icon, subUseCases, viewInActionCta } = useCase;
  const [isHovered, setIsHovered] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  // ViewInAction only enabled for desktop
  const isDesktop = useResponsiveValue([false, false, false, true]);
  const isViewInActionEnabled = !!viewInActionCta && isDesktop;

  const isViewInActionCtaActive = isViewInActionEnabled && isHovered;
  const isViewInActionModalActive = isViewInActionEnabled && isModalOpen;

  const subUseCaseListStyles = useMemo(
    () =>
      subUseCases.length <= 1
        ? SINGLE_ITEM_LIST_STYLES
        : MULTIPLE_ITEMS_LIST_STYLES,
    [subUseCases.length],
  );

  const handleMouseEnter = useCallback(
    () => setIsHovered(true),
    [setIsHovered],
  );
  const handleMouseLeave = useCallback(
    () => setIsHovered(false),
    [setIsHovered],
  );

  return (
    <React.Fragment>
      {isViewInActionModalActive ? (
        <GenericModal
          showModal={isModalOpen}
          closeModal={onCloseModal}
          template={viewInActionCta.genModalContent}
        />
      ) : null}
      <Box
        sx={CONTAINER_STYLES}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isViewInActionCtaActive ? (
          <Box
            sx={{
              position: 'absolute',
              top: '-20px',
              right: '-20px',
            }}
          >
            <Button
              type="secondary"
              buttonSx={{
                backgroundColor: '#fff',
                paddingBlock: ['4px', '4px'],
                paddingInline: ['20px', '20px'],
                zIndex: 1,
                display: 'flex',
                gap: '8px',
              }}
              onClick={() => setIsModalOpen(true)}
            >
              <Icon
                iconName="sprinklr"
                iconSx={{
                  maxHeight: '16px',
                  width: '16px',
                  flex: 'none',
                }}
              />
              <Text>{'VIEW IN ACTION'}</Text>
            </Button>
          </Box>
        ) : null}
        <Icon iconName={icon?.icon} />
        <Box sx={CONTENT_CONTAINER_STYLES}>
          <Text sx={TITLE_TEXT_STYLES}>{title}</Text>
          <ul sx={subUseCaseListStyles}>
            {subUseCases.map(subUseCase => (
              <li key={subUseCase}>
                <span>{subUseCase}</span>
              </li>
            ))}
          </ul>
        </Box>
      </Box>
    </React.Fragment>
  );
};

// libs
import React from 'react';
import { Box } from 'theme-ui';

// components
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { Grid } from 'theme-ui';
import UseCaseCard from '../UseCaseCard';

// types
import { UseCaseCategory as UseCaseCategoryType } from '../../types';

type UseCaseCategoryProps = {
  useCaseCategory: UseCaseCategoryType;
};

export const UseCaseCategory = ({ useCaseCategory }: UseCaseCategoryProps) => {
  const { title, icon, useCases } = useCaseCategory;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          fontWeight: 700,
          fontSize: ['20px', '36px'],
          textTransform: 'capitalize',
          lineHeight: '44px',
          color: '#0E61F6',
        }}
      >
        <Icon iconName={icon?.icon} />
        {title}
      </Box>
      <Grid
        columns={[1, 1, 2, 3]}
        sx={{
          gap: '28px',
          justifyContent: 'center',
          justifyItems: 'center',
        }}
      >
        {useCases.map(useCase => (
          <UseCaseCard useCase={useCase} key={useCase.id} />
        ))}
      </Grid>
    </Box>
  );
};

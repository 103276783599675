// libs
import React from 'react';

// components
import { Title } from '@sprinklr/shared-lib';
import { Flex } from 'theme-ui';

// components
import { UseCaseCategory } from './components/useCaseCategory';

// types
import { Props } from './types';

export const UseCaseTemplate = ({ title, description, categories }: Props) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: ['20px', '32px', '72px'],
      }}
    >
      <Title title={title} description={description} />
      {categories.map(useCaseCategory => (
        <UseCaseCategory
          useCaseCategory={useCaseCategory}
          key={useCaseCategory.id}
        />
      ))}
    </Flex>
  );
};
